import { makeAutoObservable, runInAction } from "mobx";
import { getEmployeePayroll } from "../services/payroll/getPayroll";

export default class PayrollStore {
  payrollDataList = null;
  payrollData = null;
  loading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchEmployeePayroll = async (employeeId, durationIn, durationOut) => {
    this.loading = true;
    this.error = null;

    try {
      const response = await getEmployeePayroll(
        employeeId,
        durationIn,
        durationOut
      );
      runInAction(() => {
        this.payrollData = response;
        this.payrollDataList = response?.employeePayrollItems;
        this.loading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err.message || "Failed to fetch payroll data";
        this.loading = false;
      });
    }
  };

  clearPayrollData = () => {
    runInAction(() => {
      this.payrollDataList = null;
    });
  };

}
