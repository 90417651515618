import React from "react";
import { Table } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import dayjs from "dayjs";

const formatTime = (time) => {
  if (!time || time === "00:00:00") return "-"; // Handle null, undefined, or midnight (00:00:00)

  const parsedTime = dayjs(`1970-01-01 ${time}`, "YYYY-MM-DD HH:mm:ss");
  return parsedTime.isValid() ? parsedTime.format("h:mm A") : "-";
};

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Time In",
    dataIndex: ["attendanceRecords", "timeIn"],
    key: "timeIn",
    render: (value) => formatTime(value),
  },
  {
    title: "Time Out",
    dataIndex: ["attendanceRecords", "timeOut"],
    key: "timeOut",
    render: (value) => formatTime(value),
  },
  {
    title: "No. of Hrs.",
    dataIndex: "noOfHours",
    key: "noOfHoursours",
  },
  {
    title: "OT",
    dataIndex: "overtimes",
    key: "overtimes",
  },
  {
    title: "OT Hrs",
    dataIndex: "otHoursIfApplicable",
    key: "otHoursIfApplicable",
  },
  {
    title: "Offset",
    dataIndex: "isOffset",
    key: "isOffset",
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "Leave",
    dataIndex: "isLeave",
    key: "isLeave",
    render: (value) => (value ? "Yes" : "No"),
  },
];

const PayrollList = () => {
  const { payrollStore } = useStore();
  const { payrollDataList } = payrollStore;
  return (
    <div style={{ padding: 24, width: "100%" }}>
      <Table columns={columns} dataSource={payrollDataList} />
    </div>
  );
};

export default observer(PayrollList);
