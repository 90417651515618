import { useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import { PrinterOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import PayrollList from "../../payroll/PayrollList";
import PropTypes from "prop-types";

const ViewSalaryBtn = () => {
  ViewSalaryBtn.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: "#F78D2E" }}
        onClick={showModal}
      >
        <MoneyCollectOutlined />
        VIEW SALARY
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
      >
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col style={{ flex: 1, textAlign: "center" }}>
            <h1>CREATE PAYROLL</h1>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Col>
        </Row>
        <PayrollList />
        <Row justify={"end"}>
          <Col>
            <h3>Total Salary</h3>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default observer(ViewSalaryBtn);
