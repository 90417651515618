import { api } from "../services";

const BASE_URL = "/accounting/employee-payroll";

export const usePayroll = () => {
  const create = async (data) => {
    const payroll = await api.post(BASE_URL, data);
    return payroll;
  };

  return {
    create,
  };
};
