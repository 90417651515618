import { useState, useRef } from "react";
import { Modal, Button, Row, Col, Form, Input, DatePicker } from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import DebounceSelect from "../../common/DebounceSelect";
import { useEmployee } from "../../../app/hooks/useEmployee";
import PayrollList from "../../payroll/PayrollList";
import { useStore } from "../../../app/stores/store";
import { usePayroll } from "../../../app/hooks/usePayroll";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const CreatePayroll = () => {
  const [form] = Form.useForm();
  const findRef = useRef([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { payrollStore } = useStore();
  const { fetchEmployeePayroll, payrollData } = payrollStore;
  const { find } = useEmployee();
  const { create } = usePayroll();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    payrollStore.clearPayrollData();

    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      const payrollObj = {
        ...payrollData,
        totalSalary: values.totalSalary,
      };
      try {
        create(payrollObj).then(() => {
          payrollStore.clearPayrollData();
          setIsModalVisible(false);
          form.resetFields();
          toast.success("Successfully Added New Payroll", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      } catch (error) {
        console.error("Form submission error:", error);
      }
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSearchEmployee = async (value) => {
    return find(value?.toLowerCase()).then((response) => {
      findRef.current = response;
      return response.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
    });
  };

  // Trigger payroll fetch when form values change
  const handleFormChange = (changedValues, allValues) => {
    const { employeeId, durationIn, durationOut } = allValues;
    if (employeeId && durationIn && durationOut) {
      fetchEmployeePayroll(
        employeeId,
        dayjs(durationIn).format("YYYY-MM-DD"),
        dayjs(durationOut).format("YYYY-MM-DD")
      );
    }
  };

  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: "#008C27" }}
        onClick={showModal}
      >
        <PlusOutlined />
        CREATE PAYROLL
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
      >
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col style={{ flex: 1, textAlign: "center" }}>
            <h1>CREATE PAYROLL</h1>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={handleFormChange}
            onFinish={handleSave}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="EMP NUMBER" name="employeeNumber">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="EMPLOYEE NAME"
                  name="employeeId"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <DebounceSelect
                    fetchOptions={handleSearchEmployee}
                    onChange={(newValue) => {
                      const data = findRef.current.find(
                        (item) => item.id === newValue
                      );
                      form.setFieldsValue({
                        employeeId: data.id,
                        employeeNumber: data.employeeNumber,
                      });
                    }}
                    placeholder="Search Employee"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="DURATION IN"
                  name="durationIn"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="DURATION OUT"
                  name="durationOut"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <PayrollList />
            <Row justify="end">
              <Col>
                <Form.Item label="TOTAL SALARY" name="totalSalary" rules={[{ required: true, message: "Required" }]}>
                  <Input type="number"/>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center" gutter={16}>
              <Col>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default observer(CreatePayroll);
