import React, { useState } from "react";
import { Row, Col, Flex, Select } from "antd";
import { observer } from "mobx-react-lite";
import AddEmployeeBtn from "../../components/employees/buttons/AddEmployeeBtn";
import CreatePayroll from "../../components/employees/buttons/CreatePayroll";
import ViewSalaryBtn from "../../components/employees/buttons/ViewSalaryBtn";
import { ArrowDownOutlined } from "@ant-design/icons";
import EmployeeDashboard from "../../components/employees/cards/EmployeeDashboard";
import EmployeeCards from "../../components/employees/cards/EmployeeCards";
import LoadingScreen from "../../components/common/LoadingScreen";
import { useEmployee } from "../../app/hooks/useEmployee";
import { useDepartment } from "../../app/hooks/useDepartment";

const Employees = () => {
  const [selectedDepartment, setSelctedDepartment] = useState();

  const { isLoading, data, mutate } = useEmployee({
    departmentId: selectedDepartment ?? "",
    pageNumber: 0,
    pageSize: 9999,
  });
  const { data: departmentList } = useDepartment();

  return (
    <div className="employees-container">
      <EmployeeDashboard />
      <Row style={{ width: "100%" }} justify={"space-between"}>
        <Col>
          <h2 style={{ fontWeight: "bold" }}>List of Employees</h2>
        </Col>
        <Col>
          <Flex style={{ marginTop: 20 }} justify={"flex-end"}>
            <Row gutter={16}>
              <Col>
                <CreatePayroll
                  onSubmit={() => {
                    mutate();
                  }}
                />
              </Col>
              <Col>
                <ViewSalaryBtn
                  onSubmit={() => {
                    mutate();
                  }}
                />
              </Col>
              <Col>
                <AddEmployeeBtn
                  onSubmit={() => {
                    mutate();
                  }}
                />
              </Col>
              <Col>
                <Select
                  type="primary"
                  className="department-dropdown"
                  defaultValue={selectedDepartment}
                  style={{ marginLeft: 10, width: 150 }}
                  placeholder="DEPARTMENT"
                  bordered={false}
                  onChange={(value) => {
                    if (value !== selectedDepartment)
                      setSelctedDepartment(value);
                  }}
                  suffixIcon={
                    <ArrowDownOutlined style={{ color: "#ffffff" }} />
                  }
                  options={departmentList?.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                />
              </Col>
            </Row>
          </Flex>
        </Col>
      </Row>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <EmployeeCards
          data={data}
          onSubmit={() => {
            mutate();
          }}
        />
      )}
    </div>
  );
};

export default observer(Employees);
